import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_BASE_API

class SettingService {
    setting() {
        return axios.get(API_URL + 'setting', { headers: authHeader() });
    }
    get(id) {
		return axios.get(API_URL + 'setting/'+id, { headers: authHeader() });
    }
	update(id, setting) {
        return axios.post(API_URL + 'setting/'+id, setting, { headers: authHeader() });
    }
    updateImagesetting(id, setting) {
        return axios.post(API_URL + 'imagesetting/'+id, setting, { headers: authHeader() });
    }
    updateCustomstyle(id, setting) {
        return axios.post(API_URL + 'customstyle/'+id, setting, { headers: authHeader() });
    }
    search() {
        return axios.get(API_URL + 'setting/search', { headers: authHeader() });
    }

}

export default new SettingService();
